import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import AccountsTab from 'components/tabs/AccountsTab';

const BillingGroupAccountsTab = () => (
  <SimpleShowLayout>
    <AccountsTab target="billing_group_id" />
  </SimpleShowLayout>
);

export default BillingGroupAccountsTab;
